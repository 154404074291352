import { getRestCityInfo, deleteRestCity } from '@/services/setting'
import viewFrame from '@/components/viewFrame'
import history from '@/common/data/jsonData'
import { getHistoryList } from '@/services/resource'
import createTable from '@/components/createTable'
import customeTable from '@/components/customeTable'
import dialogEffect from '@/components/dialogEffect'
import jsonData from './jsonData'
import loading from '@/utils/loading.json'
import Utils from '@/utils/Utils.js'
import { mixin } from '@/mixins/viewDiolog'
export default {
  components: {
    createTable,
    viewFrame,
    customeTable,
    dialogEffect
  },
  mixins: [mixin],
  data () {
    return {
      urlParams: null,
      validateVisible: false,
      buttonObj: [],
      titleObject: {},
      custName: {},
      formData: {},
      modifyData: {},
      type: '',
      basicViewItem: jsonData.basicViewItem,
      historyTableItem: history.historyTableItem,
      dialogData: [],
      batchData: [],
      dialogVisible: false,
      historyTableData: []
    }
  },
  created () {
    const _this = this
    _this.urlParams = _this.$route.params
  },
  mounted () {
    const _this = this
    _this.init()
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = _this.$loading(loading) : _this.$store.state.app.loading.close()
    },
    // 初始化
    init () {
      const _this = this
      // Object.assign(this.titleObject, { title: `${_this.$t('menu.onlineCoupon')} `, data: _this.urlParams.id })
      _this.setGlobalLoading(true)
      getRestCityInfo(_this.urlParams.id).then(res => {
        _this.setGlobalLoading(false)
        _this.$message({
          type: 'success',
          message: _this.$t('message.loadSuccess')
        })
        this.$set(this, 'custName', res.data.rest_city)
        console.log(_this.custName)
      })
      //  获取历史操作
      getHistoryList({ item_type: 'City', item_id: _this.urlParams.id }).then(res => {
        if (res.status === 200) {
          this.historyTableData = res.data.versions
        }
      })
    },
    handleBasicInfoForwardToPage (data, name) {
    },
    async handleClick (type, data) {
      if (type === 'create') {
        this.$router.push({
          name: 'citiesUpdate'
        })
      }
      if (type?.action === 'view') {
        this.$router.push({
          name: 'citiesDetail',
          params: {
            id: this.urlParams.id
          }
        })
      }
      if (type?.action === 'create') {
        this.$router.push({
          name: 'citiesUpdate'
        })
      }
      if (type?.action === 'edit') {
        this.$router.push({
          path: '/settingManager/cities/update',
          query: {
            id: this.urlParams.id
          }
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(this.urlParams.id, deleteRestCity, 'citiesList')
      }
    },
    editCouponProduct (couponId) {
      this.$router.push({
        name: 'couponProductList',
        query: {
          id: couponId
        }
      })
    },
    handleCusClick (item) {
      Utils.custOperate(item, this)
    }
  }
}
